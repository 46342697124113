.report-error-text-field-div {
    
}
.report-error-text-field-row {
    width: 100%;
    display: flex;
}

.report-error-text-field {
    width: 100%;
}

@media (min-width: 1200px) {
    .report-error-text-field-row {
        flex-direction: row;
    }

    .report-error-text-field-div div:first-child {
        margin-right: 10px !important;
    }

    .report-error-text-field {
        margin: 5px 0 !important;
    }
}

@media only screen and (max-width: 1200px) {
    .report-error-text-field-row {
        flex-direction: column;
    }
    
    .report-error-text-field {
        margin: 5px 0 !important;
    }
}
