.login-body {
    background-color: #dc940a;
    background-image: url("data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 100 1000'%3E%3Cg %3E%3Ccircle fill='%23DC940A' cx='50' cy='0' r='50'/%3E%3Cg fill='%23dd971f' %3E%3Ccircle cx='0' cy='50' r='50'/%3E%3Ccircle cx='100' cy='50' r='50'/%3E%3C/g%3E%3Ccircle fill='%23df9b2d' cx='50' cy='100' r='50'/%3E%3Cg fill='%23e09e39' %3E%3Ccircle cx='0' cy='150' r='50'/%3E%3Ccircle cx='100' cy='150' r='50'/%3E%3C/g%3E%3Ccircle fill='%23e1a244' cx='50' cy='200' r='50'/%3E%3Cg fill='%23e2a54e' %3E%3Ccircle cx='0' cy='250' r='50'/%3E%3Ccircle cx='100' cy='250' r='50'/%3E%3C/g%3E%3Ccircle fill='%23e3a958' cx='50' cy='300' r='50'/%3E%3Cg fill='%23e3ad62' %3E%3Ccircle cx='0' cy='350' r='50'/%3E%3Ccircle cx='100' cy='350' r='50'/%3E%3C/g%3E%3Ccircle fill='%23e4b06b' cx='50' cy='400' r='50'/%3E%3Cg fill='%23e4b475' %3E%3Ccircle cx='0' cy='450' r='50'/%3E%3Ccircle cx='100' cy='450' r='50'/%3E%3C/g%3E%3Ccircle fill='%23e4b77e' cx='50' cy='500' r='50'/%3E%3Cg fill='%23e4bb88' %3E%3Ccircle cx='0' cy='550' r='50'/%3E%3Ccircle cx='100' cy='550' r='50'/%3E%3C/g%3E%3Ccircle fill='%23e4be91' cx='50' cy='600' r='50'/%3E%3Cg fill='%23e4c29a' %3E%3Ccircle cx='0' cy='650' r='50'/%3E%3Ccircle cx='100' cy='650' r='50'/%3E%3C/g%3E%3Ccircle fill='%23e4c6a3' cx='50' cy='700' r='50'/%3E%3Cg fill='%23e3c9ad' %3E%3Ccircle cx='0' cy='750' r='50'/%3E%3Ccircle cx='100' cy='750' r='50'/%3E%3C/g%3E%3Ccircle fill='%23e2cdb6' cx='50' cy='800' r='50'/%3E%3Cg fill='%23e1d1c0' %3E%3Ccircle cx='0' cy='850' r='50'/%3E%3Ccircle cx='100' cy='850' r='50'/%3E%3C/g%3E%3Ccircle fill='%23dfd5c9' cx='50' cy='900' r='50'/%3E%3Cg fill='%23ded8d2' %3E%3Ccircle cx='0' cy='950' r='50'/%3E%3Ccircle cx='100' cy='950' r='50'/%3E%3C/g%3E%3Ccircle fill='%23DCDCDC' cx='50' cy='1000' r='50'/%3E%3C/g%3E%3C/svg%3E");
    background-attachment: fixed;
    background-size: contain;
}

.login-body {
    display: flex;
    align-items: center;
    justify-content: center;
    height: 100vh;
}

.login-wrapper {
    width: 400px;
    height: 400px;
    background-color: #eee;
    display: flex;
    flex-direction: column;
    padding: 0 0 1em 0;
}
.login-header {
    height: 120px;
    width: 100%;
    display: flex;
    align-items: center;
    justify-content: center;
    margin-bottom: 40px;
    border-bottom: 1px solid #00000010;
}

.login-form {
    width: 100%;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
}

.reset-password-form {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
}

.login-logo {
    height: 40px;
}

.custom-input {
    width: 80%;
    border: none;
}
.custom-input .MuiInputBase-formControl, .custom-reset-password-input .MuiInputBase-formControl{
    border-radius: 10px;
}

.custom-reset-password-input {
    margin-top: 10px;
}

.password-input {
    margin-top: 20px !important;
}

.last-password-input {
    margin-top: 10px !important;
}

.login-input:focus {
    border: none;
}
label.Mui-focused {
    color: #dc940a !important;
}
.sign-in-button {
    color: white !important;
    background-color: #dc940a !important;
    padding: 5px 20px !important;
    border-radius: 10px !important;
    text-transform: none;
}

.sign-in-button-wrapper {
    margin-top: 20px;
    width: 80%;
    display: flex;
    justify-content: center;
}

.field-input {
    margin-top: 20px !important;
}

@media only screen and (max-width: 1200px) {
    .login-wrapper {
        width: 80%;
    }
}

@media only screen and (min-width: 1200px) {
    .user-reviews-popup .MuiPaper-root {
        width: 100%;
    }
}