a:link {
    text-decoration: none;
    color: inherit;
}

a:visited {
    text-decoration: none;
    color: inherit;
}

a:hover {
    text-decoration: none;
    color: inherit;
}

a:active {
    text-decoration: none;
    color: inherit;
}

.MuiIconButton-root,
.MuiIconButton-root:hover {
    background-color: transparent !important;
}
.MuiSvgIcon-fontSizeMedium {
    color: grey;
}

.navbar {
    width: 100%;
    height: 50px;
    background-color: #000;
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: space-around;
    border-bottom: 1px solid #eeeeee40;
}

.menu-wrapper {
    padding: 15px;
}

.user-icon {
    height: 30px;
}

.menu-company-title {
    font-weight: bold;
}

.header-iyilestir-logo {
    height: 25px;
    cursor: pointer;
}

.source-paper {
    border-radius: 10px !important;
}

.header {
    display: flex;
    flex-direction: row;
    justify-content: start;
    padding: 70px 250px 0 250px;
}

.header-with-sub-company {
    padding: 30px 250px 0 250px !important;
}

.my-carousel {
    padding: 25px 50px;
}

.source-card {
    min-height: auto;
    height: auto;
    width: auto;
    border-radius: 10px !important;
    padding: 10px 15px 15px 15px;
}

.source-title {
    font-size: 20px;
    font-weight: bold;
    color: white;
    margin-bottom: 5px;
}

.source-desc {
    font-size: 18px;
    color: white;
    display: flex;
    flex-direction: row;
    align-items: center;
}

.source-desc-image {
    height: 15px;
    margin-right: 15px;
    color: white;
}

.source-card-wrapper {
    display: block;
    color: transparent;
}

.source-getir {
    background-color: #5d3ebc !important;
}

.source-iyilestir {
    background-color: #dc940a !important;
}

.source-yemeksepeti {
    background-color: #ea004b !important;
}

.source-googleplace {
    background-color: #0371f4 !important;
}

.source-sikayetvar {
    /* background-color: #695de9 !important; */
    background-color: grey !important;
}

.yemeksepeti-slider .MuiSlider-thumb {
    color: #ea004b;
}
.yemeksepeti-slider .MuiSlider-rail {
    color: #ea004b;
}
.yemeksepeti-slider .MuiSlider-track {
    color: #ea004b;
}

.getir-slider .MuiSlider-thumb {
    color: #5d3ebc;
}
.getir-slider .MuiSlider-rail {
    color: #5d3ebc;
}
.getir-slider .MuiSlider-track {
    color: #5d3ebc;
}

.google-slider .MuiSlider-thumb {
    color: #0371f4;
}
.google-slider .MuiSlider-rail {
    color: #0371f4;
}
.google-slider .MuiSlider-track {
    color: #0371f4;
}

.iyilestir-slider .MuiSlider-thumb {
    color: #dc940a;
}
.iyilestir-slider .MuiSlider-rail {
    color: #dc940a;
}
.iyilestir-slider .MuiSlider-track {
    color: #dc940a;
}

.Mui-disabled .MuiSlider-track,
.Mui-disabled .MuiSlider-rail,
.Mui-disabled .MuiSlider-thumb {
    color: grey;
}

.body {
    display: flex;
    justify-content: center;
    align-items: flex-start;
}

.filter-header-div {
    display: flex;
    justify-content: center;
    align-items: center;
    padding: 30px 10px 0 0;
    width: 100%;
    flex-direction: column;
}

.total-review-cell {
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: row;
    margin-right: 20px;
}

.total-review-image {
    height: 15px;
    margin-right: 10px;
    fill: black !important;
    color: black !important;
}

.filter-text {
    margin-left: 20px;
}

.review-count {
    display: flex;
    justify-content: center;
    align-items: center;
}

.filter-header {
    font-size: large;
    padding: 10px 20px;
    display: flex;
    width: 100%;
    justify-content: space-between;
    align-items: center;
    background-color: #f4f3f391;
}

.horizontal-line {
    width: 100%;
    height: 0.08px;
    background-color: #00005541;
}

.clickable-review-card-wrapper {
    cursor: pointer;
}

.review-card-wrapper {
    background-color: white;
    border-bottom: 0.5px solid #eeeeee20;
}

.review-card {
    background-color: white;
    padding: 20px;
    width: 100%;
}

.review-header {
    display: flex;
    justify-content: space-between;
}

.name-surname-text {
    font-size: 18px;
    font-weight: bold;
    color: black;
}

.review-name-surname-wrapper {
    display: flex;
    align-items: center;
    justify-content: center;
    margin-bottom: 10px;
}

.dot {
    background-color: #bbb;
    border-radius: 50%;
    display: inline-block;
}

.user-reviews-seperator {
    color: gray;
    height: 6px;
    width: 6px;
    margin: 4px 10px 0 10px;
}

.user-reviews-count {
    color: #aaa;
    font-size: 14px;
    text-decoration: underline;
}

.client-review-count-wrapper {
    display: flex;
    align-items: center;
    justify-content: center;
    cursor: pointer;
}

.user-reviews-image {
    margin-left: 4px;
    margin-top: 3px;
    height: 15px;
    fill: #aaa !important;
    color: #aaa !important;
}

.review-content-text {
    font-size: 16px;
    color: #888888;
    white-space: pre-line;
}

.review-date {
    display: flex;
    justify-content: end;
    font-size: 14px;
    color: #888888;
}

.review-average-rating {
    font-size: 16px;
}

.source-logo-wrapper {
    width: 100px;
    height: auto;
    display: flex;
    justify-content: center;
    align-items: center;
}

.clickable-item {
    width: 15px;
    background-color: #eeeeee80;
    display: flex;
    align-items: center;
    justify-content: center;
}

.clickable-item-icon {
    width: 20px !important;
}

.source-body-wrapper {
    display: flex;
    flex-direction: row;
}

.getir-logo {
    height: 50px;
}

.yemeksepeti-logo {
    height: 17px;
}

.iyilestir-logo {
    height: 12px;
}

.slider-panel {
    margin-top: 20px;
}

.yemeksepeti-slider-text {
    font-size: 18px;
    margin: 10px 0;
}

.custom-slider {
    margin-left: 10px;
    width: 90%;
}

.review-count {
    font-size: 18px;
}

.checkbox-row {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: center;
}

.checkbox-cell-row {
    display: flex;
    flex-direction: row;
    justify-content: end;
    align-items: center;
}

.checkbox-cell {
    display: flex;
    justify-content: center;
    align-items: center;
    width: 20px;
    border: 1px solid #eee;
    margin-left: 10px;
    padding: 3px;
    border-radius: 5px;
    font-size: 14px;
}

.change-company-wrapper {
    width: 100%;
    margin-top: 30px;
    display: flex;
    justify-content: center;
}

.change-company-autocomplete {
    width: 300px;
    border: none;
}

.user-icon {
    height: 20px;
}

.skeleton-row {
    display: flex;
    flex-direction: row;
    align-items: center;
}

.statistic-button {
    width: 100%;
    background-color: #00b30f !important;
    justify-content: space-between !important;
}

::-webkit-scrollbar {
    width: 0px;
}

@media (min-width: 1200px) {
    .source-paper {
        margin-right: 30px;
        min-width: 200px;
        max-width: 500px;
        width: auto;
    }
    .source-checkbox-div {
        width: 100%;
    }

    .reviews-div {
        width: 55%;
    }
    .filter-div {
        margin-right: 50px;
        width: 15%;
        position: sticky;
        top: 30px;
    }
    .body {
        padding: 50px 0 50px 0;
    }

    .header-company-logo {
        height: 25px;
    }

    .react-datepicker-popper[data-placement^="bottom"]
        .react-datepicker__triangle::after,
    .react-datepicker-popper[data-placement^="bottom"]
        .react-datepicker__triangle::before {
        left: -120px !important;
    }

    .review-card-wrapper {
        padding: 0 20px 0 20px;
    }
}

@media only screen and (max-width: 1200px) {
    .source-paper {
        min-width: 100px;
        width: auto;
    }
    .body {
        flex-direction: column;
    }
    .filter-div {
        width: 100%;
    }

    .source-checkbox-div {
        padding: 5px 35px;
        width: 90%;
    }

    .reviews-div {
        width: 100%;
        margin-top: 20px;
    }

    .header-company-logo {
        height: 50px;
    }

    .datepicker-input {
        margin-left: 10px;
    }

    .react-datepicker-popper[data-placement^="bottom"]
        .react-datepicker__triangle::after,
    .react-datepicker-popper[data-placement^="bottom"]
        .react-datepicker__triangle::before {
        left: -120px !important;
    }

    #root {
        overflow: hidden;
    }

    html {
        overflow-x: hidden;
    }
}

.review-date {
    margin-top: 10px;
}

/* DatePicker */

.react-datepicker__input-container input {
    width: 100%;
}

.date-picker-wrapper {
    margin-top: 10px;
    margin-right: 12px;
    margin-bottom: 10px;
}

.react-datepicker__header {
    background-color: white !important;
    border-bottom: 1px solid #aeaeae60;
}

.react-datepicker__navigation-icon::before {
    border-color: #eeeeee40;
    height: 6px;
    width: 6px;
}

.datepicker-input {
    display: flex;
    justify-content: center;
    align-items: center;
    border: 1px solid #eee;
    border-radius: 10px;
    padding: 10px;
    text-align: center;
    color: #888888;
}

.datepicker-input img {
    height: 20px;
    width: 20px;
    margin-left: 5px;
}

.react-datepicker {
    background-color: #fcfcfc;
    border: none !important;
}

.react-datepicker__current-month {
    margin-bottom: 8px;
}

.react-datepicker__month-container {
    border-color: #f4f4f4;
}

.react-datepicker__navigation-icon::before {
    border-width: 1px 1px 0 0 !important;
}