a:link {
    text-decoration: none;
    color: inherit;
}

a:visited {
    text-decoration: none;
    color: inherit;
}

a:hover {
    text-decoration: none;
    color: inherit;
}

a:active {
    text-decoration: none;
    color: inherit;
}

.body {
    margin-top: 25px;
    display: flex;
    justify-content: center;
    align-items: flex-start;
}

.statistic-header-text {
    font-size: 20px;
    margin-top: 25px;
    font-weight: bold;
    color: rgb(37, 37, 37);
}

.statistic-desctiption-text {
    margin-top: 10px;
    font-size: 14px;
    color: rgb(37, 37, 37);
}

.paper-user-retention {
    background-color: white !important ;
    min-width: 170px;
    width: 100%;
    height: 200px;
    display: flex;
    flex-wrap: wrap;
    align-content: center;
}
.paper-user-retention-text {
    width: 100%;
    text-align: center;
    justify-content: center;
    display: flex;
    font-size: x-large;
    gap: 8px;
    margin: 6px;
}

.paper-user-retention-text-title {
    width: 100%;
    text-align: center;
    font-size: larger;
    font-weight: bold;
    color: #10101090;
    margin: 6px;
    height: 40%;
    display: flex;
    align-items: center;
    justify-content: center;
}

/* desktop */
@media (min-width: 1200px) {
    .chart-wrapper {
        margin-top: 30px;
    }
    .right-side {
        width: 50%;
    }
    #chart {
        width: 95%;
    }
    .statistic-cards-container {
        padding: 0 0 20px 0 !important;
        margin-bottom: 30px !important;
        margin-top: 30px !important;
    }

    .statistic-header {
        /* margin-left: 15%; */
        display: flex;
        align-items: center;
        flex-direction: column;
    }
}

/* mobile */
@media only screen and (max-width: 1200px) {
    .chart-wrapper {
        width: 100%;
        margin-top: 30px;
    }
    .statistic-cards-container {
        padding: 10px 20px !important;
        margin-bottom: 30px !important;
        margin-top: 30px !important;
    }

    .statistic-header {
        padding: 10px;
    }

    .right-side {
        width: 100%;
    }
}
